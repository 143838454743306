import React from "react";
import CheckoutCart from "../Icons/CheckoutCart";
import MimiLogo from "../../Assets/Css/imgs/mimi_logo.png";

const Header = ({ setOpenCart, openCart, showDialog, closeDialog, showBadge }) => {
  return (
    <div className="header-container">
      <div className="dwell-logo">
        <img src={MimiLogo} />
      </div>

      <div className={`icon-wrapper `}>
        <ul className="nav-items">
 
          <li>
            <a href="https://miminyc.com/info/" target="_blank">ABOUT</a>
          </li>
        </ul>
        <div
          className="cart_icon"
          style={{ width: "40px", cursor: "pointer" }}
          onClick={() => {
            setOpenCart(!openCart);
          }}
        >
          {showBadge ? <div className="bag-badge"></div> : ""}
          <CheckoutCart />
        </div>
      </div>
      <div
          className="mobile_cart_icon"
          style={{ width: "40px", cursor: "pointer" }}
          onClick={() => {
            setOpenCart(!openCart);
          }}
        >
          {showBadge ? <div className="bag-badge"></div> : ""}
          <CheckoutCart />
        </div>
    </div>
  );
};

export default Header;
