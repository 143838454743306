import React from "react";

const Hero = ({ heroData }) => {
  function NewlineText(paragraph) {
    const text = paragraph;
    const newText = text.split("\n").map((str) => (
      <>
        {str}
        <br />
      </>
    ));

    return newText;
  }

  return (
    <div className="container">
      <div className="hero-container ">
        <div className="hero-text-container">
          <h1 className="noe-font-title hero-h1">
            {heroData?.header ? heroData?.header : "MIMI Wine Club"}
          </h1>
          <h3 className="hero-h3">
            {heroData?.paragraph ? (
              NewlineText(heroData?.paragraph)
            ) : (
              <>
               Monthly, you'll receive our favorite wines to drink at that moment. If you're not ready to commit to the full-time relationship (even though you can cancel anytime) then there's an option for a one-time purchase.
                <br />
                <br />
                Any questions? Contact us at thewineclub@parcellewine.com
              </>
            )}
            <>
            <br className='p_break'/>
            <span className='contact_span'>
            Any questions? Contact us at{' '}
            <a href="mailto:thewineclub@parcellewine.com"> thewineclub@parcellewine.com </a> <br />
            </span>
            </>
            <br />
          </h3>
        </div>
      </div>
      {/* <div className=" subscribe_monthly  "> */}
      <h1 className="product_section_header">
        {heroData?.productSectionHeader
          ? heroData?.productSectionHeader
          : "Subscribe monthly or try a single collection"}
      </h1>
      {/* </div> */}
    </div>
  );
};

export default Hero;
