const axios = require("axios");
export const datoHeader = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_DATO_CMS_TOKEN}` },
};

const server =
  process.env.NODE_ENV === "production"
    ? "https://my-app-pt73t.ondigitalocean.app/"
    : "http://localhost:3007/";
console.log("Config");
export async function queryDatoCms(query) {
  const answer = await axios({
    url: "https://graphql.datocms.com/",
    method: "post",
    data: {
      query: query,
    },
    ...datoHeader,
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  return answer;
}

export async function queryParcelleDb(query) {
  const answer = await axios({
    url: `${server}w_l_parcelle/${query}`,
    method: "get",
  })
    .then((result) => {
      return result?.data?.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  return answer;
}

export async function queryShopify(endUrl, query) {
  const answer = await axios({
    url: server + endUrl,
    method: "get",
    data: {
      query: query,
    },
  })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
  return answer;
}
