export const GET_PRODUCTS = ` 
query     
{
  allMimiProducts(orderBy: price_ASC) {
    price,
    id,
 _createdAt,
 _status,
 createdAt,
 shopifyVariantId,
 oneTimeVariantId,
 productTitle,
 productSku,
 active,
 subtitle, 
 featuredImage {
    url,
   alt,
   width,
   height,
   format,
   alt,
   blurUpThumb,
   blurhash,
   filename
 },
 productImages {
   url,
   alt,
   width,
   height,
   format,
   alt,
   blurUpThumb,
   blurhash,
   filename
 },
 productDescription,
 secondTitle,
 productId,
 sortOrder,
 shopifygraphqlid
  }
}`;

export const GET_HERO = `
query {
  mimiHero {
    header
    paragraph
    productSectionHeader
  }
}`;
